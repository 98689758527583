@brkM: unit( ((768 + 320) / 2), px );
@brkT: unit( ((1024 + 768) / 2), px );
@brkD: unit( ((1200 + 1024) / 2), px );

@mdM: ~"(max-width: @{brkM})";
@mdT: ~"(max-width: @{brkT})";
@mdD: ~"(max-width: @{brkD})";

.m (@rules) {
    @media @mdM { @rules(); }
}

.t (@rules) {
    @media @mdT { @rules(); }
}

.d (@rules) {
    @media @mdD { @rules(); }
}