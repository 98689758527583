[ng\:cloak], [ng-cloak], [data-ng-cloak], [x-ng-cloak], .ng-cloak, .x-ng-cloak {
  display: none !important;
}

.error {
  white-space: nowrap;
  line-height: 46px;
  margin-left: 20px;
  color: #BE3733;
}

button.ti__btn {
  background-color: rgba(0, 0, 0, 0);
}

.ng-invalid.ng-touched, .ng-invalid.submitted, .email-error {
  border-bottom-color: #BE3733;
}